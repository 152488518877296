import isEmpty from 'lodash/isEmpty'
import { MENU_DEPOSIT, MENU_DEPOSIT_BANK, MENU_DEPOSIT_DESKTOP } from '~/constants/menu'
import { useAccountStore } from '~/store/account'
import { ACCOUNT_URLS } from '~/config/page-url'
import { useNuxtApp, useRoute, useRouter } from '#app'
import { IMenuDeposit, IMenuDepositBank, IMenuDepositDesktop } from '~/types/menu.type'
import { useToggleMenu } from '~/composables/useToggleMenu'

export const useDepositMenu = () => {
  const { $device } = useNuxtApp()
  const { toggleMenuDeposit } = useToggleMenu()
  const menuDeposit = ref<IMenuDeposit[]>(MENU_DEPOSIT)
  const menuDepositDesktop = ref<IMenuDepositDesktop[]>(MENU_DEPOSIT_DESKTOP)
  const menuDepositBank = ref<IMenuDepositBank[]>(MENU_DEPOSIT_BANK)
  const accountStore: any = useAccountStore()
  const route = useRoute()
  const router = useRouter()
  const updateDataMenuDeposit = () => {
    const isMaintainMomo: boolean = accountStore.momos?.length === 0
    const isMaintainViettelPay: boolean = accountStore.viettelPays?.length === 0
    return MENU_DEPOSIT.map((item: IMenuDeposit) => {
      let isMaintain:boolean = false
      if (item.key === 'cardList') {
        isMaintain = accountStore.cardList?.VIETTEL?.status !== 1
            && accountStore.cardList?.MOBIFONE?.status !== 1
        && accountStore.cardList?.VINAPHONE?.status !== 1
        &&  accountStore.cardList?.ZING?.status !== 1
      } else if (item.key === 'providerGoPay') {
        isMaintain = isEmpty(accountStore.providerGoPay) || !accountStore.providerGoPay?.GOPAY?.bankings.length
      } else if (item.key !== 'momos' && item.key !== 'viettelPays') {
        if (Array.isArray(accountStore[item.key])) {
          isMaintain = accountStore[item.key].every(
            (bank: any) => bank.is_under_maintenance || bank.is_statement
          )
        }
      } else {
        isMaintain = isMaintainMomo && isMaintainViettelPay
      }
      return {
        ...item,
        isMaintain
      }
    })
  }

  const updateDataMenuDepositDesktop = () => {
    const isMaintainMomo: boolean = accountStore.momos?.length === 0
    const isMaintainViettelPay: boolean = accountStore.viettelPays?.length === 0

    return MENU_DEPOSIT_DESKTOP.map((item: IMenuDepositDesktop) => {
      let isMaintain:boolean = false
      if (item.key === 'cardList') {
        isMaintain = accountStore.cardList?.VIETTEL?.status !== 1
            && accountStore.cardList?.MOBIFONE?.status !== 1
            && accountStore.cardList?.VINAPHONE?.status !== 1
            &&  accountStore.cardList?.ZING?.status !== 1
      } else if (item.key !== 'e-wallet' && item.key !== 'viettelPays' && item.key !== 'nicepayBanks') {
        isMaintain = accountStore[item.key]?.length === 0
      } else if (item.key === 'nicepayBanks') {
        isMaintain = (isEmpty(accountStore.providerGoPay) || !accountStore.providerGoPay?.GOPAY?.bankings.length) && accountStore[item.key]?.length === 0
      } else {
        isMaintain = isMaintainMomo && isMaintainViettelPay
      }
      return {
        ...item,
        isMaintain
      }
    })
  }

  const updateDataMenuDepositBank = () => {
    return MENU_DEPOSIT_BANK.map((item: IMenuDepositBank) => {
      let isMaintain: boolean = false
      if (item.key === 'providerGoPay') {
        isMaintain = isEmpty(accountStore.providerGoPay) || !accountStore.providerGoPay?.GOPAY?.bankings.length
      } else {
        isMaintain = accountStore[item.key]?.length === 0
      }
      return {
        ...item,
        isMaintain
      }
    })
  }

  onMounted(() => {
    if ($device.isMobileOrTablet) {
      menuDeposit.value = updateDataMenuDeposit()
    } else {
      menuDepositDesktop.value = updateDataMenuDepositDesktop()
      menuDepositBank.value = updateDataMenuDepositBank()
    }
  })

  const isMaintainCodePay = computed(() => {
    return !accountStore?.codePayBanks
  })

  const handleTabMenuDeposit = (item: any) => {
    if (!item?.isMaintain) {
      if ($device.isMobileOrTablet) {
        toggleMenuDeposit()
        router.push(item.link)
      } else {
        router.push(item.link)
      }
    }
  }

  const updateRedirectMaintain = () => {
    let currentPath = route?.fullPath
    if (currentPath === ACCOUNT_URLS.DEPOSIT) {
      currentPath += '/bank'
      router.replace({ path: currentPath })
    }
    const pageNotMaintain = menuDeposit.value.filter((item) => item.isMaintain === false)
    const bankNotMaintain = pageNotMaintain.find((item) => item.key === 'nicepayBanks')
    const currentPage = menuDeposit.value.find((item) => item.fullPath === currentPath)
    if (currentPage && !currentPage?.isMaintain) {
      // TODO: redirect to current page
    } else if (bankNotMaintain?.fullPath || pageNotMaintain[0]?.fullPath) {
      window.open(bankNotMaintain?.fullPath || pageNotMaintain[0]?.fullPath, '_self')
    } else {
      window.open('/', '_self')
    }
  }

  watch(
    () => accountStore,
    () => {
      menuDeposit.value = updateDataMenuDeposit()
    },
    { deep: true }
  )

  return {
    menuDeposit,
    menuDepositBank,
    menuDepositDesktop,
    handleTabMenuDeposit,
    updateRedirectMaintain,
    isMaintainCodePay
  }
}
